@import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');

@font-face {
  font-family: 'frutiger';
  src: url("../src/assets/fonts/Frutiger-LT-Std-45-Light_18818.ttf");
  font-weight: normal;
  font-style: normal;
}

:root {
  --bar-width: 30px;
  --bar-height: 4px;
  --hamburger-gap: 3px;
  --animation-timing: 200ms ease-in-out;
  --hamburger-height: calc(var(--bar-height) * 2 + var(--hamburger-gap));
  --heading-color: #2e1325;
  --general-text-color: #7b7a7b;
  --square1-color: #eae2e7;
  --square2-color: #f3f3f3;
}

#root {
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "frutiger", sans-serif;
  margin: 0;
}

.toggle-inLine {
  display: block;
}

.toggle-hamburger {
  display: none;
}

.show-menu {
  display: flex;
  list-style-type: none;
  justify-content: space-between;
  padding: 0;
  gap: 20px;
}

.menu-styles a {
  text-decoration: none;
  font-family: 'Abel', sans-serif;
  color: var(--general-text-color);
  font-size: 1rem;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: var(--square2-color);
  padding: 2rem;
}

.logo {
  position: absolute;
  text-decoration: none;
  background: url("../src/assets/images/logo.png") no-repeat center center;
  background-size: contain;
  width: 18rem;
  height: 10%;
  z-index: 5;
}

.titulo-secciones {
  color: var(--heading-color);
  font-size: 3rem;
  font-weight: 200;
  margin: 0;
}

.subtitulo-secciones {
  color: var(--heading-color);
  font-size: 1rem;
  font-weight: 300;
  margin: 0;
}

.titulo-texto {
  color: var(--heading-color);
  font-size: 0.8rem;
  font-weight: 400;
  margin: 0;
}

.titulo-texto span {
  font-size: 0.6rem;
}

.texto-general {
  color: var(--general-text-color);
  font-size: 0.8rem;
  font-weight: 400;
  margin: 0;
}

.titulo-form {
  color: var(--heading-color);
  font-size: 1rem;
  font-weight: 300;
  margin: 0;
}

.texto-general-valores {
  color: var(--heading-color);
}

.menu-language {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4rem;
}

.language {
  display: block;
  width: auto;
  height: fit-content; /* stylelint-disable-line */
  font-family: 'Abel', sans-serif;
  font-size: 1rem;
  border: none;
  background-color: transparent;
  color: var(--general-text-color);
  cursor: pointer;
}

.flags {
  display: none;
}

.home-wrapper {
  display: flex;
  flex-direction: column;
}

.mountains {
  background: url("../src/assets/images/mountains.png") no-repeat center center;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  position: relative;
  height: 100%;
  transform-style: preserve-3d;
  z-index: -1;
  transform: translateZ(-6px) scale(1.6);
}

.mountains p {
  width: 55%;
  text-align: center;
}

.ceo-title p {
  text-align: center;
}

.mountains p:first-child {
  margin-top: 8%;
}

.office {
  background: url("../src/assets/images/office.png") no-repeat center center;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.square-card {
  background-color: var(--square2-color);
  border-radius: 40px;
  width: 50%;
  height: 65%;
  margin: 8%;
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s 0.5s ease;
}

.appear-on-scroll {
  opacity: 1;
}

.arrow-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}

.arrows-margin-home {
  margin: 2rem;
}

.arrows-margin-servicios {
  margin: 1rem 0;
  padding-right: 1.5rem;
}

.arrow-buttons > button {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: none;
  padding: 0;
  cursor: pointer;
}

.right-arrow,
.left-arrow {
  fill: var(--heading-color);
  background-color: var(--square1-color);
  border-radius: 50%;
  padding: 5px;
}

.right-arrow:hover,
.left-arrow:hover {
  background-color: var(--heading-color);
  fill: var(--square1-color);
}

.square-wrapper {
  display: flex;
  justify-content: center;
  gap: 4rem;
  padding-left: 3rem;
  padding-bottom: 2rem;
}

.square-wrapper-last {
  justify-content: start;
}

.title-card {
  width: 30%;
}

.title-card-hidden {
  opacity: 0;
  transition: opacity 0.5s 0.8s ease;
}

.bullet-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
  margin: 0;
  padding-right: 5rem;
  transition: opacity 0.5s 1.1s ease;
}

.card-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 3rem;
  padding-right: 5rem;
}

.hidden-arrow {
  display: none;
}

.industries-list {
  padding-left: 10%;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.services-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background: url("../src/assets/images/servicios.png") no-repeat center center;
  overflow-x: hidden;
}

.services-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2em;
  width: 55%;
  max-width: 50em;
}

.services-cards {
  display: flex;
  flex-direction: column;
}

.card-position-absolute {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-end;
  opacity: 0;
  transition: opacity 0.5s 0.5s ease;
}

.primary-button {
  width: fit-content;
  padding: 2% 4%;
  background-color: var(--heading-color);
  color: var(--square2-color);
  font-family: 'Abel', sans-serif;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  text-decoration: none;
}

.primary-button:hover {
  background-color: var(--square2-color);
  color: var(--heading-color);
}

.services-info-container {
  display: flex;
  background-color: white;
  border-radius: 40px;
  justify-content: flex-start;
  align-self: center;
  width: 100%;
}

.services-img-container {
  background-color: var(--square2-color);
  border-radius: 40px 0 0 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6%;
}

.services-img-container img {
  max-width: 90%;
}

.services-img {
  opacity: 0;
  transition: opacity 0.5s 0.8s ease;
}

.services-info-wrapper {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  padding-left: 5%;
}

.services-info {
  opacity: 0;
  transition: opacity 0.5s 1.1s ease;
}

.services-info-wrapper ul {
  padding: 0;
  padding-left: 6%;
  padding-right: 9%;
}

.lower-alpha {
  list-style: lower-alpha;
}

.bullet-points {
  list-style: disc;
}

.services-bullets-wrapper {
  margin-top: 3em;
}

.desktop-arrows {
  display: block;
}

.mobil-arrows {
  display: none;
}

.desktop-service-button {
  /* display: block; */
  display: none;
}

.mobil-service-button {
  display: none;
}

.equipo-wrapper {
  background: url("../src/assets/images/equipo.png") no-repeat center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  padding: 10% 5%;
}

.equipo-containter {
  display: flex;
  width: 70rem;
  justify-content: center;
  border-radius: 40px 0 0 40px;
  height: 70vh;
  opacity: 0;
  transition: opacity 0.5s 0.5s ease;
}

.team-description {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
  max-width: 20%;
  background-color: var(--square1-color);
  border-radius: 40px 0 0 40px;
  padding: 10% 5% 0 5%;
}

.appear-team-title {
  opacity: 0;
  transition: opacity 0.5s 0.8s ease;
}

.team-member {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 8%;
  background-color: white;
  border-radius: 0 40px 40px 0;
  padding: 10% 5% 0 5%;
}

.team-member-hidden {
  opacity: 0;
  transition: opacity 1s 2s ease;
}

.ceo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.5rem;
  align-items: center;
  opacity: 0;
  transition: opacity 0.5s 1.1s ease;
}

.ceo-container img {
  width: 20vh;
}

.ceo-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ceo-resume {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  max-width: 60%;
  opacity: 0;
  transition: opacity 0.5s 1.1s ease;
}

.white-space {
  white-space: pre-wrap;
}

.met-container {
  background: url("../src/assets/images/metodologia.png") no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  padding: 5%;
}

.met-title {
  margin-bottom: 5%;
  padding-left: 6%;
  align-self: flex-start;
}

.metodology-wrapper {
  width: 71em;
}

.met-cards {
  background-color: var(--square1-color);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;
  padding: 4% 5%;
  border-radius: 40px;
  margin-bottom: 2%;
  width: 64rem;
  opacity: 0;
  transition: opacity 0.5s 0.5s ease;
}

.met-card-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
  padding: 2% 5%;
  width: 30%;
  height: 14em;
  border-radius: 40px;
  background-color: white;
  opacity: 0;
  animation-duration: 0.5s;
  animation-delay: 0.5s;
  animation-name: appear-in;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}

.met-card-num {
  background-color: var(--square2-color);
  width: 1em;
  text-align: center;
  padding: 2%;
  border-radius: 50%;
}

.met-card-list {
  display: flex;
  flex-direction: column;
  list-style: disc;
  padding: 0;
  padding-right: 10%;
  padding-left: 14px;
  margin: 0;
}

.reveal-card-1 {
  animation-delay: 0.8s;
}

.reveal-card-2 {
  animation-delay: 1.1s;
}

.reveal-card-3 {
  animation-delay: 1.4s;
}

.reveal-card-4 {
  animation-delay: 1.7s;
}

@keyframes appear-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.met-cierre {
  background-color: var(--square2-color);
  border-radius: 40px;
  padding: 4% 25%;
  text-align: center;
  width: auto;
}

.contacto-container {
  display: flex;
  flex-direction: column;
  padding: 5%;
  align-items: flex-start;
  background-color: var(--square2-color);
}

.contacto-container a {
  align-self: center;
  padding: 1% 4%;
}

.contacto-container a:hover {
  border: 2px solid var(--heading-color);
}

.forms-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  justify-content: center;
  gap: 4rem;
  padding: 5%;
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-row {
  display: flex;
  gap: 10px;
}

.form-column {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.input-text {
  border-radius: 10px;
  border: 1px solid var(--heading-color);
  height: 2rem;
}

.input-format {
  padding: 0 8px;
  color: var(--heading-color) !important;
}

.text-area {
  height: 4rem;
  padding-top: 8px;
}

.email-error {
  color: red;
}

.form-footer {
  display: flex;
  justify-content: space-between;
}

.form-submition {
  background-color: green;
  color: yellowgreen;
  padding: 15px 50px;
  margin: 0;
  text-align: center;
  border-radius: 10px;
  transform: translateX(150%);
  opacity: 0;
  transition: all 0.5s 0.5s ease;
}

.slide-in {
  transform: translateX(0);
  opacity: 1;
}

.secondary-button {
  width: 25%;
  align-self: flex-start;
  padding: 15px;
  color: var(--heading-color);
  background-color: white;
  border: 2px solid var(--heading-color);
  border-radius: 25px;
  cursor: pointer;
}

.secondary-button:hover {
  background-color: var(--heading-color);
  color: white;
}

.footer-container {
  background-color: var(--heading-color);
  display: flex;
  justify-content: space-between;
  padding: 5% 8%;
}

.footer-data {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  align-items: center;
}

.texto-footer {
  color: var(--square2-color);
  font-size: 1rem;
  margin: 0;
}

.social-media {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.active {
  opacity: 1;
}

.hidden {
  opacity: 0;
}

.parallax-wrapper {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  perspective: 10px;
}

.parallax-sections {
  position: relative;
  transform-style: preserve-3d;
  z-index: -1;
  transform: translateZ(-10px) scale(2);
}

/* Media queries

pumas
pumas
pumas
pumas
pumas

@media screen and (min-width: 1500px) {

}

*/

@media (max-height: 2000px) {
  .logo {
    height: 4%;
  }

  .square-card {
    max-height: 28rem;
  }

  .equipo-containter {
    height: 33em;
  }
}

@media (max-height: 1500px) {
  .logo {
    height: 6%;
  }

  .square-card {
    max-height: 28rem;
  }

  .equipo-containter {
    height: 33em;
  }

  .equipo-wrapper {
    height: 80vh;
    align-items: center;
  }
}

@media (max-height: 1000px) {
  .logo {
    height: 10%;
  }

  .equipo-containter {
    height: 30em;
  }
}

@media (max-width: 1250px) {
  .menu-styles a {
    font-size: 1rem;
  }

  .show-menu {
    gap: 10px;
  }

  .square-card {
    width: 75%;
    height: 70%;
  }

  .square-wrapper-last {
    gap: 6rem;
  }

  .services-info-container {
    width: 100%;
    height: 13rem;
  }

  .services-info-wrapper ul {
    padding-right: 5%;
    padding-left: 5%;
  }

  .equipo-containter {
    width: 100%;
  }

  .metodology-wrapper {
    width: 100%;
  }

  .met-cards {
    width: 90%;
  }

  .met-card-wrapper {
    height: auto;
    max-height: 20em;
  }
}

@media (max-width: 900px) {
  .equipo-containter {
    height: 36em;
  }

  .services-info-container {
    height: 16rem;
  }
}

@media (max-width: 750px) {
  .toggle-inLine {
    display: none;
  }

  .toggle-hamburger {
    display: block;
  }

  .logo {
    background: url("../src/assets/images/logo-mobil.png") no-repeat center center;
    background-size: contain;
    width: 50%;
    height: 4%;
  }

  .hamburger-menu::before,
  .hamburger-menu::after {
    content: '';
    width: var(--bar-width);
    height: var(--bar-height);
    background-color: var(--general-text-color);
    transform-origin: left center;
    transition:
      width var(--animation-timing),
      rotate var(--animation-timing),
      translate var(--animation-timing);
    z-index: 5;
  }

  .hamburger-menu {
    --x-width: calc(var(--hamburger-height) * 1.41421356237);

    display: flex;
    flex-direction: column;
    gap: var(--hamburger-gap);
    border-radius: 10px;
    z-index: 5;
  }

  .hamburger-menu input {
    content: '';
    appearance: none;
    padding: 0;
    max-height: 0.5px;
    margin: 0;
    outline: none;
    pointer-events: none;
    z-index: 5;
  }

  .hamburger-menu:has(input:checked)::before {
    rotate: 45deg;
    width: var(--x-width);
    translate: 0 calc(var(--bar-height) / -9);
  }

  .hamburger-menu:has(input:checked)::after {
    rotate: -45deg;
    width: var(--x-width);
    translate: 0 calc(var(--bar-height) / 9);
  }

  .menu-language {
    flex-direction: row-reverse;
    gap: 10%;
    margin-top: 3px;
  }

  .language {
    display: none;
  }

  .flags {
    display: block;
    width: 40px;
    height: 20px;
    border: none;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .us-flag {
    background-image: url("../src/assets/images/us-flag.png");
  }

  .mexico-flag {
    background-image: url("../src/assets/images/mexico-flag.png");
  }

  .nav-links {
    top: 0;
    left: 0;
    bottom: 0;
    position: absolute;
    background-color: var(--square2-color);
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    z-index: 1;
  }

  .show-menu {
    flex-direction: column;
    margin-top: 25%;
    gap: 0.5rem;
    position: absolute;
  }

  .mountains {
    background: url("../src/assets/images/Home-vert.png") no-repeat center center;
    background-size: cover;
  }

  .mountains p {
    width: 80%;
    text-align: justify;
  }

  .mountains p:first-child {
    margin-top: 16%;
  }

  .office {
    min-height: 150vh;
  }

  .card-text {
    padding-right: 1.5rem;
  }

  .bullet-container {
    padding-right: 1.5rem;
  }

  .square-card {
    position: relative;
    width: 85%;
    height: fit-content;/* stylelint-disable-line */
    max-height: 60em;
  }

  .square-wrapper {
    flex-direction: column;
    padding-left: 3rem;
    padding-right: 3rem;
    gap: 3rem;
  }

  .arrow-button {
    margin-top: 10%;
    margin-left: 80%;
  }

  .title-card {
    width: auto;
  }

  .services-container {
    flex-direction: column;
    justify-content: center;
    gap: 18em;
    background-size: cover;

    /* height: 54rem;
    propiedad con boton VER MAS */
    height: 50rem;
  }

  .services-wrapper {
    /* gap: 16em;
    Propiedad con boton VER MAS */
    gap: 10em;
    width: 80%;
  }

  .services-cards {
    background-position: bottom;
    position: static;
  }

  .card-position-absolute {
    align-items: baseline;
    margin-top: 3rem;
    gap: unset;
  }

  .services-info-container {
    flex-direction: column;
    justify-content: end;
    height: 20rem;
    border-radius: 0 0 40px 40px;
  }

  .services-img-container {
    border-radius: 40px 40px 0 0;
    flex-direction: column;
    padding: 0;
    justify-content: flex-start;
  }

  .services-img-container img {
    max-width: 55%;
    max-height: 55%;
  }

  .services-info-wrapper {
    padding-left: 10%;
    flex-direction: column-reverse;
    width: auto;

    /* padding-bottom: 6rem;
    Padding con voton ver mas activo */
    padding-bottom: 3rem;
  }

  .services-bullets-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2em;
    height: 10rem;
  }

  .primary-button {
    padding: 2% 10%;
  }

  .desktop-arrows {
    display: none;
  }

  .mobil-arrows {
    display: block;
    align-self: flex-end;
  }

  .desktop-service-button {
    display: none;
  }

  .mobil-service-button {
    /* display: block; */
    display: none;
    align-self: flex-end;
    margin-right: 4rem;
    opacity: 0;
    transition: opacity 0.5s 1.1s ease;
  }

  .active {
    opacity: 1;
  }

  .equipo-wrapper {
    padding: 25% 10%;
    height: fit-content;/* stylelint-disable-line */
    max-height: 175vh;
  }

  .equipo-containter {
    flex-direction: column;
    background-color: var(--square1-color);
    height: auto;
    border-radius: 40px;
    padding-bottom: 20%;
  }

  .team-description {
    max-width: unset;
    border-radius: 40px;
    background-color: white;
    padding: 20% 15%;
  }

  .appear-team-title p:first-child {
    margin-bottom: 2rem;
  }

  .team-member {
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    background-color: var(--square1-color);
    border-radius: 0 0 40px 40px;
  }

  .ceo-resume {
    max-width: 80%;
  }

  .met-container {
    margin: 0;
    padding-top: 10%;
    padding-bottom: 20%;
  }

  .met-title {
    margin: 10% 0 10% 10%;
    padding-left: 0;
  }

  .met-cards {
    flex-wrap: nowrap;
    flex-direction: column;
    background-color: white;
    gap: 0;
    padding: 0;
    width: 100%;
    margin-bottom: 0;
  }

  .met-card-wrapper {
    width: auto;
    height: 15em;
    padding: 10% 10% 0 10%;
    border-radius: 40px;
  }

  .met-card-wrapper:first-child {
    padding-bottom: 10%;
  }

  .met-card-wrapper:nth-child(even) {
    background-color: var(--square1-color);
    border-radius: 0;
  }

  .met-card-wrapper:last-child {
    height: 12em;
  }

  .met-cierre {
    padding: 10%;
    background-color: white;
    border-radius: 0 0 40px 40px;
  }

  .form-container {
    width: -webkit-fill-available; /* stylelint-disable-line */
  }

  .form-row {
    flex-direction: column;
  }

  .contacto-container {
    padding: 14% 5%;
  }

  .contacto-titulo {
    padding: 0 5%;
  }
}
